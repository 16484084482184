const api = {

	//菜单
	menuList: '/api/leave_sys_menu_list',
	menuSave: '/api/leave_sys_menu_edit',
	menuDel: '/api/leave_sys_menu_delete',
	//用户角色(todo ok)
	roleList: '/api/leave_sys_role_list',
	roleSave: '/api/leave_sys_role_edit',
	roleDel: '/api/leave_sys_role_delete',
	getRoleMenu: '/api/leave_get_role_menu',
	setRoleMenu: '/api/leave_set_role_menu',


}
export default api
